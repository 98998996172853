/* eslint-disable filenames/match-exported */
import merge from 'lodash/merge';

import type { State } from '../../interfaces/State';
import * as selectors from '../../redux/selectors';

import { BaseExerciseService } from './base';

class ComposedExercise extends BaseExerciseService {
  prepareSubmit(state: State, action: any) {
    const commandConfig = merge(
      {},
      selectors.selectExercise(state).toJS(),
      selectors.selectCurrentSubExercise(state).toJS(),
    );
    commandConfig.pec = commandConfig.pre_exercise_code;

    const dimension = selectors
      .selectGraphicalTabs(state)
      .getIn(['plot', 'dimension'])
      .toJS();

    const config = {
      ...commandConfig,
      ...action.settings,
      ...dimension,
    };
    if (config.command === 'console' && config.language === 'sql') {
      config.command = 'run';
    }
    if (
      ['r', 'python'].includes(config.language) &&
      config.type === 'BulletConsoleExercise'
    ) {
      config.command = 'submit';
    }

    return config;
  }
}

const ComposedExerciseSingleton = new ComposedExercise();
export default ComposedExerciseSingleton;
