import { useCallback, useState } from 'react';
import store from 'store';

export const STORAGE_KEYS = {
  NOTES_POPUP_SEEN: 'dc_campus_notesPopupSeen',
  NOTES_USED: 'dc_campus_notesUsed',
  LEARNING_RECAP_EVER_SEEN: 'dc_campus_learnRecapEverSeen',
  LEARNING_RECAP_LAST_SEEN: 'dc_campus_learnRecapLastSeen',
  LEARNING_RECAP_SKIP_COUNT: 'dc_campus_learnRecapSkipCount',
  LEARNING_RECAP_TMP_DISABLED_UNTIL: 'dc_campus_learnRecapTmpDisabledUntil',
  MOBILE_APP_SCREEN_SEEN: 'dc_campus_learnMobileAppScreenSeen',
} as const;
type StorageKey = typeof STORAGE_KEYS[keyof typeof STORAGE_KEYS];

export const useStorage = <T>(
  key: StorageKey,
  defaultValue?: T,
): { setValue: (value: T) => void; value: T } => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = store.get(key);
      return item ?? defaultValue;
    } catch (error) {
      return defaultValue;
    }
  });

  const setValue = useCallback(
    (value: T) => {
      setStoredValue(value);
      store.set(key, value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key],
  );

  return { value: storedValue, setValue };
};
