/* eslint-disable filenames/match-exported */
import { getGlobalEditorContent } from '@datacamp/code-editor';
import findKey from 'lodash/findKey';
import reduce from 'lodash/reduce';
import startsWith from 'lodash/startsWith';

import type { State } from '../../interfaces/State';
import type { IEpicSubmitCodeAction } from '../../redux/actions';
import * as selectors from '../../redux/selectors';

import type { PartialSubmitCodeSettings } from './base';
import { BaseExerciseService } from './base';

class MarkdownExerciseService extends BaseExerciseService {
  getCodesOfTabs(
    tabs: any[],
    onlySolutionTabs: boolean,
  ): { [key: string]: string } {
    return reduce(
      tabs,
      (acc: { [key: string]: string }, tab, key: string) => {
        const startsWithSolution = startsWith(key, 'solution_');
        if (
          (startsWithSolution && onlySolutionTabs) ||
          (!startsWithSolution && !onlySolutionTabs)
        ) {
          acc[tab.title] = getGlobalEditorContent(tab.props.uniqueId) || '';
        }
        return acc;
      },
      {},
    );
  }

  prepareSubmit(
    state: State,
    action: IEpicSubmitCodeAction,
  ): PartialSubmitCodeSettings {
    const commandSettings = super.prepareSubmit(state, action);
    const tabs = selectors.selectInputMarkdownTabs(state).toJS();
    const activeKey = findKey(tabs, (t) => t.props.active);

    const codeObject = this.getCodesOfTabs(
      tabs,
      startsWith(activeKey, 'solution_'),
    );

    return {
      ...commandSettings,
      code: JSON.stringify(codeObject),
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      activeTab: activeKey.replace('solution_', ''),
    };
  }
}

const MardownExerciseServiceSingleton = new MarkdownExerciseService();
export default MardownExerciseServiceSingleton;
