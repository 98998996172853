import superagent from 'superagent';

import config from '../../config';

export class HttpError extends Error {
  response: any;

  constructor(message: any, response: any) {
    super(message);

    this.response = response;
  }
}

const isArray = (obj: any) => obj instanceof Array;

let jwtTokenPromise: any;
const getJwtToken = () => {
  if (jwtTokenPromise == null) {
    jwtTokenPromise = superagent
      .get(`${config.serviceUrls.mainApp}/api/jwt/token`)
      .accept('json')
      .withCredentials()
      .then((res: any) => res.body.jwt_token);
  }

  return jwtTokenPromise;
};

export async function fetch(
  url: any,
  { data, headers, method = 'get', options = {}, query }: any,
) {
  const agent = options.agent || superagent;
  let request = agent[method.toLowerCase()](url);

  if (query) {
    request = request.query(query);
  }
  if (data) {
    request = request.send(data);
  }
  if (options.json) {
    request = request.serialize().type('json');
  }
  if (options.type) {
    request = request.type(options.type);
  }
  if (options.accept) {
    request = request.accept(options.accept);
  }
  if (headers) {
    request = request.set(headers);
  }
  if (options.withCredentials) {
    request = request.withCredentials();
  }
  if (options.responseType) {
    request = request.responseType(options.responseType);
  }
  if (options.timeout) {
    request = request.timeout(options.timeout);
  }

  if (options.withJwtToken) {
    request = request.set('Authorization', `Bearer ${await getJwtToken()}`);
  }

  if (isArray(options.auth)) {
    request = request.auth(...options.auth);
  }
  if (isArray(options.redirects)) {
    request = request.redirects(options.redirects);
  }
  if (isArray(options.attaches)) {
    request = options.attaches
      .filter(isArray)
      .reduce((acc: any, attach: any) => acc.attach(...attach), request);
  }
  if (isArray(options.fields)) {
    request = options.fields
      .filter(isArray)
      // @ts-expect-error ts-migrate(6133) FIXME: 'acc' is declared but its value is never read.
      .reduce((acc: any, field: any) => request.field(...field), request);
  }

  const response = await request;

  if (!response) {
    throw new HttpError('No or empty response', response);
  }
  if (options.only2xx && !response.ok) {
    throw new HttpError('Status code was not 2xx', response);
  }

  return response;
}
