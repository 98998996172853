import { z } from 'zod';

import { isFeatureEnabled } from '../featureFlags';

export const aiFeatureConfigSchema = z.object({
  // enabledCourseIds is a list of course ids that the feature is enabled for
  enabledCourseIds: z.array(z.number()).or(z.literal('all')),
  // enabledExerciseTypes is a list of exercise types that the feature is enabled for
  enabledExerciseTypes: z.array(z.string()).nonempty(),
  // the splitIO flag that enables this feature for b2c users (if empty, the feature is enabled for all b2c users)
  splitFlag: z.optional(z.string()),
  // b2bEnabled is a flag that enables the feature for b2b users if the group hub flag is enabled
  b2bEnabled: z.boolean(),
  // the group hub flag that enables this feature for b2b users
  b2bFlag: z.optional(z.string()),
  // b2bEnabledCourseIds is a list of course ids that the feature is enabled for b2b users. Only used if b2bEnabled is true
  b2bEnabledCourseIds: z.optional(z.array(z.number()).or(z.literal('all'))),
});

export const aiFeatureConfigMapSchema = z.object({
  aiHints: aiFeatureConfigSchema,
});
export type AiFeatureConfigMapSchema = z.infer<typeof aiFeatureConfigMapSchema>;

const coursesEnabledForAiHintsB2B = [
  // Python
  735,
  799,
  22066,
  28314,
  1532,
  22639,
  // R
  58,
  672,
  4914,
  // SQL
  29302,
  29303,
  29304,
  13367,
] as const;

export const aiFeatureConfig = aiFeatureConfigMapSchema.parse({
  aiHints: {
    enabledCourseIds: 'all',
    enabledExerciseTypes: ['NormalExercise'],
    splitFlag: 'cp--incorrect-submission-ai-hints',
    b2bEnabled: true,
    b2bFlag: 'aiErrorExplanationEnabled',
    b2bEnabledCourseIds: coursesEnabledForAiHintsB2B,
  },
});

export const isAiFeatureEnabled = ({
  courseId,
  exerciseType,
  feature,
  isB2BFlagEnabled,
  isUserB2B,
  isUserFromDatacamp,
}: {
  courseId: number;
  exerciseType: string;
  feature: keyof AiFeatureConfigMapSchema;
  isB2BFlagEnabled: boolean;
  isUserB2B: boolean;
  isUserFromDatacamp: boolean;
}): boolean => {
  const config = aiFeatureConfig[feature];
  if (
    config.enabledCourseIds !== 'all' &&
    !config.enabledCourseIds.includes(courseId)
  ) {
    return false;
  }
  if (!config.enabledExerciseTypes.includes(exerciseType)) {
    return false;
  }
  if (isUserFromDatacamp) {
    return true;
  }
  if (isUserB2B) {
    if (!config.b2bEnabled) {
      return false;
    }
    return isAiFeatureEnabledForB2BUsers({
      config,
      courseId,
      isB2BFlagEnabled,
    });
  }

  if (config.splitFlag === undefined) {
    return true;
  }
  return isFeatureEnabled(config.splitFlag);
};

const isAiFeatureEnabledForB2BUsers = ({
  config,
  courseId,
  isB2BFlagEnabled,
}: {
  config: AiFeatureConfigMapSchema[keyof AiFeatureConfigMapSchema];
  courseId: number;
  isB2BFlagEnabled: boolean;
}): boolean => {
  if (config.b2bFlag === undefined) {
    return false;
  }
  if (!isB2BFlagEnabled) {
    return false;
  }

  if (config.b2bEnabledCourseIds === undefined) {
    return true;
  }
  if (
    config.b2bEnabledCourseIds !== 'all' &&
    !config.b2bEnabledCourseIds.includes(courseId)
  ) {
    return false;
  }
  return true;
};
