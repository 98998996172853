import type { IExerciseContract } from '@datacamp/exercise-contract';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import React, { PureComponent } from 'react';

import ExerciseContract from './ExerciseContract';
import LocalStandaloneExercise from './LocalStandaloneExercise';

type Props = {
  // import { ExerciseEnvironment } from '@datacamp/exercise-contract';
  // TODO: PropTypes.oneOf(Object.keys(ExerciseEnvironment))
  environment?: any;
  id?: number;
  isActive: boolean;
  isStandaloneExercise: boolean;
  nextIdsByExerciseType?: {
    [key: string]: number[];
  };
  onMultiplexerStatusUpdated: (...args: any[]) => any;
  onNext: (...args: any[]) => any;
  onSubmitted: (...args: any[]) => any;
  onXpUpdated: (...args: any[]) => any;
  resources?: IExerciseContract['resources'];
  type?: string;
  user?: {
    authenticationToken: string;
    email: string;
    hasActiveSubscription: boolean;
    id?: number;
    isLoggedIn: boolean;
  };
};

type State = {
  exerciseTypes: string[];
};

export default class StandaloneExercises extends PureComponent<Props, State> {
  static getDerivedStateFromProps(props: any, state: any) {
    if (isEmpty(props.type)) {
      return null;
    }
    if (
      !includes(state.exerciseTypes, props.type) &&
      props.isStandaloneExercise
    ) {
      return {
        exerciseTypes: [...state.exerciseTypes, props.type],
      };
    }
    return null;
  }

  state = {
    exerciseTypes: [],
  };

  render() {
    const {
      environment,
      id,
      isActive,
      isStandaloneExercise,
      nextIdsByExerciseType,
      onMultiplexerStatusUpdated,
      onNext,
      onSubmitted,
      onXpUpdated,
      resources,
      type,
      user,
    } = this.props;
    return this.state.exerciseTypes.map((exerciseType) => (
      <ExerciseContract
        key={exerciseType}
        id={exerciseType === type ? id : undefined}
        nextIds={get(nextIdsByExerciseType, exerciseType, [])}
        type={exerciseType}
        isActive={isActive}
        environment={environment}
        user={user}
        onSubmitted={onSubmitted}
        onMultiplexerStatusUpdated={onMultiplexerStatusUpdated}
        onXpUpdated={onXpUpdated}
        onNext={onNext}
        resources={resources}
        isCurrent={exerciseType === type && isStandaloneExercise}
      >
        {({ isCurrent, isReady, setExerciseClient }: any) => (
          <LocalStandaloneExercise
            type={exerciseType}
            // @ts-expect-error ts-migrate(2769) FIXME: Property 'isReady' does not exist on type 'Intrins... Remove this comment to see the full error message
            isReady={isReady}
            isCurrent={isCurrent}
            setExerciseClient={setExerciseClient}
          />
        )}
      </ExerciseContract>
    ));
  }
}
