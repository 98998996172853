import type { Search } from 'history';
import { Map as hashMap } from 'immutable';
import forEach from 'lodash/forEach';
import keys from 'lodash/keys';
import result from 'lodash/result';
import startsWith from 'lodash/startsWith';
import qs from 'qs';

import config from '../config';
import { DEFAULT_LANGUAGE, languagePathRegex } from '../i18n';
import type { LearningModeState } from '../redux/reducers/learningMode';

export const formatUrl = (
  courseRef: number | string,
  chapterRef: number | string,
  exerciseNumber: number,
  optionalQuery?: hashMap<string, any>,
  prependLanguage?: string,
): string => {
  const queryFormatted = removeDefaultQueryParams(
    qs.stringify({
      ...(optionalQuery && optionalQuery.toJS()),
      ex: exerciseNumber,
    }),
  );
  const path = formatPrettyPath(
    { courseRef, chapterRef },
    {
      isPreviewMode: false,
      prependLanguage,
      prependCampusSubfolder:
        !config.isServerSideRendering &&
        window.location.pathname.startsWith('/campus/'),
    },
  );

  return `${path}${queryFormatted}`;
};

export const continueTrackUrl = (
  courseRef: number | string,
  chapterRef: number | string,
): string =>
  `${config.urls.datacamp}/courses/${courseRef}/chapters/${chapterRef}/continue_track`;

type ContentNavigationRef = {
  chapterRef: number | string;
  courseRef: number | string;
};
type FormatPrettyPathArgs = {
  isPreviewMode: boolean;
  prependCampusSubfolder: boolean;
  prependLanguage?: string;
};
export const formatPrettyPath = (
  { chapterRef, courseRef }: ContentNavigationRef,
  {
    isPreviewMode,
    prependCampusSubfolder,
    prependLanguage,
  }: FormatPrettyPathArgs,
): string => {
  let path = '';
  if (prependLanguage && prependLanguage !== DEFAULT_LANGUAGE) {
    path += `/${prependLanguage}`;
  }
  if (prependCampusSubfolder) {
    path += '/campus';
  }
  if (isPreviewMode) {
    path += '/preview';
  }
  path += `/courses/${courseRef}/${chapterRef}`;
  return path;
};

const getChapterByRef = (chapters: any, chapterRef: any) =>
  chapters &&
  chapters.find(
    (chapter: any) =>
      chapterRef === chapter.get('slug') ||
      Number(chapterRef) === Number(chapter.get('id')),
  );

export const generateNextUrl = ({
  chapters,
  courseRef,
  currentChapter,
  currentExNb,
  language,
  learningMode,
  nbExercises,
  query,
}: {
  chapters: any;
  courseRef: any;
  currentChapter: any;
  currentExNb: any;
  language?: string;
  learningMode: LearningModeState;
  nbExercises: any;
  query: any;
}): string | null => {
  let nextExNumber = currentExNb + 1;
  let chapterForNextEx = currentChapter;
  if (nextExNumber > nbExercises) {
    // currentChapter.number start to count from 1 and not 0 like a list
    if (learningMode === 'singleChapter') {
      return continueTrackUrl(
        courseRef,
        currentChapter.get('slug') || currentChapter.get('id'),
      );
    }
    chapterForNextEx = chapters.get(currentChapter.get('number'));
    if (chapterForNextEx && chapterForNextEx.get('nb_exercises')) {
      nextExNumber = 1;
    } else {
      return `${config.urls.datacamp}/courses/${courseRef}`;
    }
  }
  let nextExUrl = null;
  if (nextExNumber && courseRef && chapterForNextEx) {
    nextExUrl = formatUrl(
      courseRef,
      chapterForNextEx.get('slug') || chapterForNextEx.get('id'),
      nextExNumber,
      query,
      language,
    );
  }
  return nextExUrl;
};

export const generatePrevUrl = ({
  chapters,
  courseRef,
  currentChapter,
  currentExNb,
  language,
  learningMode,
  query,
}: {
  chapters: any;
  courseRef: any;
  currentChapter: any;
  currentExNb: any;
  language?: string;
  learningMode: LearningModeState;
  query: any;
}): string | null => {
  let previousExNumber = currentExNb - 1;
  let chapterForPreviousEx = currentChapter;
  if (previousExNumber < 1) {
    const index = Number(currentChapter.get('number')) - 2;
    if (index < 0 || learningMode === 'singleChapter') {
      return null;
    }
    chapterForPreviousEx = chapters.get(index);
    if (chapterForPreviousEx) {
      previousExNumber = chapterForPreviousEx.get('nb_exercises');
    } else {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
      previousExNumber = null;
    }
  }
  let previousExUrl = null;
  if (previousExNumber && courseRef && previousExNumber) {
    previousExUrl = formatUrl(
      courseRef,
      chapterForPreviousEx.get('slug') || chapterForPreviousEx.get('id'),
      previousExNumber,
      query,
      language,
    );
  }
  return previousExUrl;
};

const navigation = (
  course: any,
  chapters: any,
  nbExercises: any,
  { chapterRef, courseRef, exerciseNumber, learningMode }: any,
  query: any,
  language?: string,
) => {
  const currentChapter = getChapterByRef(chapters, chapterRef);
  if (currentChapter && exerciseNumber && courseRef && chapterRef) {
    const visibleChapters = course.get('reduced_outline')
      ? hashMap()
      : chapters;
    return {
      nextExercise: generateNextUrl({
        currentExNb: exerciseNumber,
        currentChapter,
        nbExercises,
        courseRef,
        chapters: visibleChapters,
        query,
        learningMode,
        language,
      }),
      previousExercise: generatePrevUrl({
        currentExNb: exerciseNumber,
        currentChapter,
        courseRef,
        chapters: visibleChapters,
        query,
        learningMode,
        language,
      }),
    };
  }
  return {};
};
export default navigation;

export const getSplittedPathName = (
  pathname: string,
): { chapterRef: string; courseRef: string } => {
  const filteredPathname = pathname
    .replace(languagePathRegex, '/')
    .replace(/^(\/campus\/preview\/|\/campus\/|\/preview\/)/, '/');
  const pathnameSplitted = filteredPathname.split('/');
  const [, , courseRef, chapterRef] = pathnameSplitted;
  return { courseRef, chapterRef };
};

// wrapper/proxy for setting the window location, used for testing navigation to different superdomain in Cypress
export const setWindowNavigator = (): void => {
  window.setLocation = (path: any) => {
    window.location.assign(path);
  };
};

export const navigateTo = (history: any, path: any): void => {
  if (startsWith(path, 'http')) {
    window.setLocation(path);
    return;
  }

  /*
   * Remove the language from the path if it exists due to the basename
   * set in `history` (see src/index.ts file) otherwise it causes a duplicate
   * /es/es in the URL.
   *
   * https://github.com/remix-run/history/tree/v4.7.2#using-a-base-url
   */
  const pathWithoutBasename = path.replace(languagePathRegex, '/');

  history.push(pathWithoutBasename);
};

export const refreshPage = () => {
  if (result(window, 'location.reload')) {
    window.location.reload();
  }
};

export const isExercisePage = (pathname: string) =>
  /\/courses\//.test(pathname);

type QueryParam = 'learningMode';

const defaultQueryParams: Record<QueryParam, string> = {
  learningMode: 'course',
};

export const removeDefaultQueryParams = (search: Search) => {
  const query = qs.parse(search, { ignoreQueryPrefix: true });

  forEach(keys(defaultQueryParams), (defaultQueryParam) => {
    const isDefaultQueryParam =
      query[defaultQueryParam] ===
      defaultQueryParams[defaultQueryParam as QueryParam];
    if (isDefaultQueryParam) {
      query[defaultQueryParam] = undefined;
    }
  });

  return qs.stringify(query, { addQueryPrefix: true });
};
