import type Multiplexer from '@datacamp/multiplexer-client';
import { PLUGIN_NAME as MUX_NAME } from '@datacamp/multiplexer-client';
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/datacamp__plugin-manager` ... Remove this comment to see the full error message
import PluginManager from '@datacamp/plugin-manager';

// create a singleton to handle all the plugins
const pluginManager = new PluginManager();

export const getMux = (): Multiplexer.AsyncSession =>
  pluginManager.get(MUX_NAME);

export default pluginManager;
