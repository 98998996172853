import type { ConstructorOptions } from 'raven';

import config from '../config';

export const SENTRY_DSN =
  'https://995c49df51c04fbeabeceab8a2d5eb3b@sentry.io/132290';

export const RAVEN_CONFIG: ConstructorOptions = {
  environment: config.ecsEnv,
  release: config.release,
  tags: {
    serverSide: config.isServerSideRendering.toString(),
  },
  maxBreadcrumbs: 30,
};
