import isEmpty from 'lodash/isEmpty';
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/universal-rx-request` if i... Remove this comment to see the full error message
// eslint-disable-next-line no-restricted-imports
import rxRequest from 'universal-rx-request';
import { z } from 'zod';

import config from '../config';
import { DEFAULT_LANGUAGE, X_DC_LANG_HEADER } from '../i18n';

import { getVisitorSettings } from './api/clients';

rxRequest.importRxExtensions();

export default class ApiClient {
  static status = rxRequest.STATUS;

  static imbEndpoints: any;

  static endpoint: any;

  apiUrl: any;

  sid: any;

  userSettings: any;

  ltiSettings?: { active: boolean; chapterId: number };

  constructor(apiUrl: any) {
    this.apiUrl = apiUrl;
    this.userSettings = {};
    this.sid = null;
  }

  execute(endpoint: any, action?: any) {
    const url = this.apiUrl + endpoint.uri;
    const requestObs = rxRequest({ url, ...endpoint });

    if (!config.isServerSideRendering && action) {
      // if this is on the client, map that to actions
      return requestObs.mapToAction(action).throwErrorOnFailedRequest();
    }
    return requestObs; // if on the server, just return it, the server will handle
  }

  setUserSettings(settings: any) {
    this.userSettings = isEmpty(settings) ? getVisitorSettings() : settings;
    return this;
  }

  getAuthenticationToken() {
    return this.userSettings.authentication_token;
  }
}

export const ActiveCourseImagesResponse = z.discriminatedUnion('type', [
  z.object({
    imageNameToImageTag: z.record(z.string(), z.string()),
    type: z.literal('multiImage'),
  }),
  z.object({
    type: z.literal('notFound'),
  }),
  z.object({
    imageTag: z.string(),
    type: z.literal('singleImage'),
  }),
]);
export type ActiveCourseImagesResponse = z.infer<
  typeof ActiveCourseImagesResponse
>;

export type Images = ActiveCourseImagesResponse;

ApiClient.imbEndpoints = {
  getCourseImages: ({
    authorizationHeader,
    courseId,
  }: {
    authorizationHeader: string | undefined;
    courseId: string;
  }) => ({
    method: 'get',
    uri: `/active_course_images/${courseId}`,
    options: {
      only2xx: true,
      headers: {
        ...(authorizationHeader ? { Authorization: authorizationHeader } : {}),
      },
    },
  }),
};

export const LearningRecapResponse = z
  .object({
    lastActiveDate: z.string().datetime(),
    derivedCourseContentId: z.number(),
    nextLessonGoal: z.string(),
    summary: z.string(),
  })
  .nullable();

export type LearningRecapResponse = z.infer<typeof LearningRecapResponse>;

export type DerivedCourseContentIssueType =
  | 'incorrect_content'
  | 'incorrect_translation'
  | 'other'
  | 'typo';

ApiClient.endpoint = {
  getCourse: (
    id: number | string,
    {
      authorizationHeader,
      language = DEFAULT_LANGUAGE,
    }: { authorizationHeader: string | undefined; language?: string },
  ) => ({
    method: 'get',
    uri: `/courses/${id}`,
    options: {
      headers: {
        Accept: 'application/json',
        [X_DC_LANG_HEADER]: language,
        ...(authorizationHeader ? { Authorization: authorizationHeader } : {}),
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  getExercises: (
    course: any,
    chapter: any,
    {
      authorizationHeader,
      language = DEFAULT_LANGUAGE,
    }: {
      authorizationHeader: string | undefined;
      language?: string;
    },
  ) => ({
    method: 'get',
    uri: `/courses/${course}/chapters/${chapter}/exercises`,
    options: {
      headers: {
        Accept: 'application/json',
        [X_DC_LANG_HEADER]: language,
        ...(authorizationHeader ? { Authorization: authorizationHeader } : {}),
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  objectExists: (url: any) => ({
    method: 'head',
    uri: url,
    options: {
      headers: {
        Accept: 'application/json',
      },
      json: true,
      only2xx: true,
    },
  }),
  signIn: () => ({
    method: 'get',
    uri: '/users/signed_in',
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  getCourseLearningRecap: ({
    authorizationHeader,
    courseId,
    language,
    nextExerciseId,
  }: {
    authorizationHeader: string | undefined;
    courseId: number;
    language: string;
    nextExerciseId: number;
  }) => ({
    method: 'get',
    uri: `/courses/${courseId}/recap`,
    options: {
      headers: {
        Accept: 'application/json',
        [X_DC_LANG_HEADER]: language,
        ...(authorizationHeader ? { Authorization: authorizationHeader } : {}),
      },
      json: true,
      only2xx: true,
      withCredentials: true,
    },
    query: { nextExerciseId },
  }),
  rateLearningRecapIssue: ({
    derivedCourseContentId,
    isHelpful,
  }: {
    derivedCourseContentId: number;
    isHelpful: boolean;
  }) => ({
    method: 'post',
    uri: `/derivedCourseContent/${derivedCourseContentId}/rate`,
    data: { isHelpful },
    options: {
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  reportLearningRecapIssue: ({
    derivedCourseContentId,
    issueType,
    language,
    message,
  }: {
    derivedCourseContentId: number;
    issueType: DerivedCourseContentIssueType;
    language: string;
    message: string;
  }) => ({
    method: 'post',
    uri: `/derivedCourseContent/${derivedCourseContentId}/issues`,
    data: { issueType, message },
    options: {
      withCredentials: true,
      json: true,
      only2xx: true,
      headers: {
        [X_DC_LANG_HEADER]: language,
      },
    },
  }),
  getCourseProgress: ({
    authorizationHeader,
    courseRef,
  }: {
    authorizationHeader: string | undefined;
    courseRef: number | string;
  }) => ({
    method: 'get',
    uri: `/courses/${courseRef}/progress`,
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        ...(authorizationHeader ? { Authorization: authorizationHeader } : {}),
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  getChapterProgress: ({
    authorizationHeader,
    chapterRef,
    courseRef,
  }: {
    authorizationHeader: string | undefined;
    chapterRef: number | string;
    courseRef: number | string;
  }) => ({
    method: 'get',
    uri: `/courses/${courseRef}/chapters/${chapterRef}/progress`,
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        ...(authorizationHeader ? { Authorization: authorizationHeader } : {}),
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  updateUser: (userId: any, userFields: any) => ({
    method: 'put',
    uri: `/users/${userId}`,
    data: userFields,
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  postFeedbackIssue: ({ category, extra_info, id, last_submission }: any) => ({
    method: 'post',
    uri: `/exercises/${id}/submit_feedback`,
    data: { category, extra_info, last_submission },
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  postFeedback: (data: any) => ({
    method: 'post',
    uri: `/rating`,
    data,
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  postSubmitCode: ({
    authentication_token,
    code,
    contentLanguage = DEFAULT_LANGUAGE,
    correct,
    feedback_msg,
    id,
    xp,
  }: any) => ({
    method: 'post',
    uri: '/exercises/submit',
    data: {
      authentication_token,
      code,
      correct,
      feedback_msg,
      id,
      xp,
      content_language: contentLanguage,
      timestamp: new Date().toISOString(),
    },
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  seenCampusTour: () => ({
    method: 'post',
    uri: '/users/has_seen_campus_tour',
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  getSolution: ({ exerciseId }: any) => ({
    method: 'get',
    uri: `/exercises/${exerciseId}/get_solution`,
    data: { timestamp: new Date().toISOString() },
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  getHint: ({ exerciseId }: any) => ({
    method: 'get',
    uri: `/exercises/${exerciseId}/get_hint`,
    data: { timestamp: new Date().toISOString() },
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  ltiIsActive: ({ chapterId, courseId }: any) => ({
    method: 'get',
    uri: `/integrations/lti/is_active?course_id=${courseId}&chapter_id=${chapterId}`,
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  submitLti: () => ({
    method: 'post',
    uri: '/integrations/lti/submit',
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  submitRating: (chapterId: any, { extraInfo, rating }: any) => ({
    method: 'post',
    uri: `/chapters/${chapterId}/submit_rating`,
    data: { rating, extra_info: extraInfo },
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  submitExerciseRating: (exerciseId: any, { extraInfo, rating }: any) => ({
    method: 'post',
    uri: `/exercises/${exerciseId}/submit_rating`,
    data: { rating, extra_info: extraInfo },
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  resetProgress: ({ courseId }: any) => ({
    method: 'put',
    uri: '/courses/reset_progress',
    data: { id: courseId },
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  postToDashboard: (data: any) => ({
    method: 'post',
    data,
    uri: '/',
    options: {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: false,
    },
  }),
  sendTimeTrackingPing: (data: any) => ({
    method: 'post',
    uri: '/',
    data,
    options: {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: false,
    },
  }),
  getSitemap: (locale = '') => {
    // if there is a locale, we use sitemap-<locale>.xml
    const language = locale ? `-${locale}` : '';

    return {
      method: 'get',
      uri: `/sitemaps/campus/sitemap${language}.xml`,
      options: {
        headers: {
          Accept: 'text/xml',
        },
      },
      query: null,
      data: null,
    };
  },
  window: ({ chapter_id, course_id, locale, type }: any) => ({
    method: 'get',
    uri: '/window',
    query: { type, course_id, chapter_id, locale },
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  getCompletedChapterCount: () => ({
    method: 'get',
    uri: '/users/completed_chapter_count',
    options: {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
      },
      withCredentials: true,
      json: true,
      only2xx: true,
    },
  }),
  getStreak: () => {
    return {
      method: 'get',
      uri: '/gamification/streaks/current',
      options: {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
        },
        withCredentials: true,
        json: true,
        only2xx: true,
      },
    };
  },
};
