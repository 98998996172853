import config from '../config';

import ApiClient from './ApiClient';

const createClient = (path: any) => {
  let client: ApiClient | null = null;
  return () => {
    if (client === null) {
      client = new ApiClient(path);
    }
    return client;
  };
};

export const getMainAppApiClient = createClient(
  `${config.serviceUrls.mainApp}/api`,
);

export const getCampusApiClient = createClient(
  `${config.serviceUrls.campusApi}/api`,
);

export const getTeachClient = createClient(`${config.serviceUrls.teach}/api`);

export const getImbClient = createClient(config.serviceUrls.imb);
