import { Map as hashMap } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React from 'react';

type Props = {
  // import ImmutablePropTypes from 'react-immutable-proptypes';
  table?: any; // TODO: ImmutablePropTypes.contains({ records: ImmutablePropTypes.listOf(ImmutablePropTypes.listOf), columns: ImmutablePropTypes.listOf(PropTypes.string), })
};

const Table: React.FC<Props> = ({ table }) => {
  const tableJS = (table || hashMap()).toJS();
  let { columns = [] } = tableJS;
  const { records = [] } = tableJS;
  if (isEmpty(columns)) {
    columns = ['Loading...'];
  }
  return (
    <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
      <table className="table table-condensed table-bordered table-hover table-striped">
        <thead>
          <tr>
            {map(columns, (name, key) => (
              <th key={key}>{name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {map(records, (record, key) => (
            <tr key={key}>
              {map(record, (item, k) => (
                <td key={k}>{String(item)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
