/* eslint-disable filenames/match-exported */
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';

import config from '../../config';
import type { WorkspaceSourceCreationInfo } from '../../redux/reducers/notes';

import { DataWarehouseSession } from './dataWarehouseSession';
import { fetch } from './http';

export function getVisitorSettings() {
  const random = Math.random().toString(36).slice(2);
  return {
    _isVisitor: true,
    email: `visitor_${random}`,
    authentication_token: `visitor_token_${random}`,
  };
}

export default class ApiClient {
  apiUrl: any;

  sid: any;

  userSettings: any;

  constructor(apiUrl: any) {
    this.apiUrl = apiUrl;
    this.userSettings = {};
    this.sid = null;
  }

  fetch({ uri, ...params }: any) {
    return fetch(`${this.apiUrl}${uri}`, params);
  }

  setUserSettings(settings: any) {
    this.userSettings = isEmpty(settings) ? getVisitorSettings() : settings;
    return this;
  }

  getAuthenticationToken() {
    return this.userSettings.authentication_token;
  }
}

export class MobileApiClient extends ApiClient {
  constructor() {
    super(config.serviceUrls.mobile);
  }

  async sendMobilePopupLink({ chapterId, courseId, exerciseId }: any) {
    return this.fetch({
      method: 'post',
      uri: '/api/sendsinglelogin',
      options: {
        json: true,
        withJwtToken: true,
      },
      data: {
        cioCampaignName: 'campus_interstitial_experiment',
        courseId,
        chapterId,
        exerciseId,
      },
    });
  }

  sendDailyStreakMobileLink({ chapterId, courseId, exerciseId }: any) {
    return this.fetch({
      method: 'post',
      uri: '/api/sendsinglelogin',
      options: {
        json: true,
        withJwtToken: true,
      },
      data: {
        cioCampaignName:
          'campus_app_download_streak_interstitial_experiment_oct24',
        courseId,
        chapterId,
        exerciseId,
      },
    });
  }

  async getFirstMobileSignInAt(): Promise<any> {
    return this.fetch({
      method: 'post',
      uri: '/graphql',
      options: {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
        },
        withJwtToken: true,
        json: true,
        only2xx: true,
      },
      data: {
        query: /* GraphQL */ `
          query {
            user: me {
              firstMobileSignInAt
            }
          }
        `,
      },
    });
  }
}

export class MainAppClient extends ApiClient {
  constructor() {
    super(config.serviceUrls.mainApp);
  }

  getSingleLoginToken() {
    return this.fetch({
      method: 'get',
      uri: '/api/single_login/token',
      options: {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
        },
        withCredentials: true,
        json: true,
        only2xx: true,
      },
    });
  }

  getTranslatedCourses() {
    return this.fetch({
      method: 'get',
      uri: '/api/translations/courses',
      options: {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
        },
        withCredentials: true,
        json: true,
        only2xx: true,
      },
    });
  }
}

export class DatawarehouseApiClient extends ApiClient {
  constructor() {
    super(config.serviceUrls.learnDataWarehouse);
  }

  async createDatawarehouseSession({ courseId }: { courseId: number }) {
    const response = await this.fetch({
      method: 'post',
      uri: `/courses/${courseId}/sessions`,
      options: {
        withCredentials: true,
        json: true,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to create data warehouse session');
    }

    return DataWarehouseSession.parse(response.body);
  }

  async getDatawarehouseSession({ sessionId }: { sessionId: string }) {
    const response = await this.fetch({
      method: 'get',
      uri: `/sessions/${sessionId}`,
      options: {
        withCredentials: true,
        json: true,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to get data warehouse session');
    }

    return DataWarehouseSession.parse(response.body);
  }

  keepDatawarehouseSessionAlive({ sessionId }: { sessionId: string }) {
    return this.fetch({
      method: 'patch',
      uri: `/sessions/${sessionId}/reset-ttl`,
      options: {
        withCredentials: true,
        json: true,
      },
    });
  }
}

export class CollabApiClient extends ApiClient {
  constructor() {
    super(config.serviceUrls.collab);
  }

  getWorkspaceTemplate({ courseId }: { courseId: number }) {
    return this.fetch({
      method: 'get',
      uri: `/template/course/${courseId}`,
      options: {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
        },
        withCredentials: true,
        json: true,
        only2xx: true,
        timeout: 3000,
      },
    });
  }

  getOwnedWorkspaceBySource({
    courseSlug,
    source,
  }: {
    courseSlug: string;
    source: WorkspaceSourceCreationInfo;
  }) {
    const queryParameters = {
      openIfExistsKey: courseSlug,
      ...source,
    };
    return this.fetch({
      method: 'get',
      uri: `/workspace/owned-by-source?${qs.stringify(queryParameters)}`,
      options: {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Accept: 'application/json',
        },
        withCredentials: true,
        json: true,
        only2xx: true,
      },
    });
  }
}

export class StrapiApiClient extends ApiClient {
  constructor() {
    super(config.serviceUrls.cms);
  }

  async getCategoryPageSlugsAndFacets() {
    const categoryPageData = await import('./category-page-cms-data.json');
    return {
      body: categoryPageData.default,
    };
  }
}

export const collabApi = new CollabApiClient();
export const mobileApi = new MobileApiClient();
export const mainApp = new MainAppClient();
export const datawarehouseApi = new DatawarehouseApiClient();
export const strapiApi = new StrapiApiClient();
