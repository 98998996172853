export const sendTagManagerEvent = (eventAttributes: any) => {
  if (typeof eventAttributes !== 'object') {
    return;
  }

  if (typeof window.dataLayer === 'undefined') {
    window.dataLayer = [eventAttributes];
  } else {
    window.dataLayer.push(eventAttributes);
  }
};
