/** @jsx jsx */
import { Link } from '@datacamp/waffles/link';
import { Paragraph } from '@datacamp/waffles/paragraph';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React from 'react';

import hcCollapsePanel from './HighOrderComponents/hcCollapsePanel';

type Props = {
  slides_link: string;
};

const Slides: React.FC<Props> = ({ slides_link: slidesLink }) => {
  const link = slidesLink.replace(/http(?!s)/g, 'https');

  return (
    <div
      data-cy="slides-container"
      css={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <iframe
        title="slides"
        src={`/campus/pdf/web/viewer.html?file=${encodeURIComponent(link)}`}
        allowFullScreen
        css={{
          minWidth: '100%',
          minHeight: '100%',
          border: 0,
        }}
      >
        <Paragraph
          css={{
            paddingLeft: tokens.spacing.large,
            paddingRight: tokens.spacing.large,
          }}
        >
          It appears your browser does not have a PDF plugin. Click{' '}
          <Link href={slidesLink} target="_blank" rel="noopener noreferrer">
            here
          </Link>{' '}
          to download the slides.
        </Paragraph>
      </iframe>
    </div>
  );
};

export { Slides as SlidesComponent };
export default hcCollapsePanel({ fromTopToBottom: true })(Slides);
