/* eslint-disable @typescript-eslint/no-var-requires */

import PropTypes from 'prop-types';
import React from 'react';

import config from '../config';

import DCSpinner from './DCSpinner';
import NormalExerciseServerLayout from './NormalExercise/NormalExerciseServerLayout';

const Universal = (ClientComponent: any, ServerComponent: any) => {
  let didAlreadyMount = false;
  return class UniversalComponent extends React.Component {
    static propTypes = {
      isApplicationBooted: PropTypes.bool.isRequired,
    };

    constructor(props: any, context: any) {
      super(props, context);
      this.state = { didAlreadyMount };
    }

    componentDidMount() {
      didAlreadyMount = true;
      this.setState({ didAlreadyMount });
    }

    render() {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'didAlreadyMount' does not exist on type ... Remove this comment to see the full error message
      if (this.state.didAlreadyMount && this.props.isApplicationBooted) {
        return <ClientComponent {...this.props} {...this.state} />;
      }
      if (!ServerComponent) {
        return <DCSpinner />;
      }
      return <ServerComponent {...this.props} {...this.state} />;
    }
  };
};

export default Universal;

const SqlLayoutExport = NormalExerciseServerLayout;
let BaseLayoutExport = NormalExerciseServerLayout;

if (!config.isServerSideRendering) {
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'typeof UniversalComponent' provides no match... Remove this comment to see the full error message
  BaseLayoutExport = Universal(
    require('./Layouts/BaseLayoutContainer').default,
    NormalExerciseServerLayout,
  );
}

export const SqlLayout = SqlLayoutExport;
export const BaseLayout = BaseLayoutExport;
