import raven from 'raven-js';

import DEFAULT_FEATURE_FLAGS from './default.json';
import type { InitSplitArgs, SplitTreatment } from './types';

const { REACT_APP_SPLIT_IO_API_KEY: API_KEY } = process.env;
let splitClient: any;
let featureOverrides: any;
let params: any;

export class SplitTimeoutError extends Error {
  // @ts-expect-error ts-migrate(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
  constructor(message = 'Split SDK initialization timed out', ...args) {
    // @ts-expect-error ts-migrate(2556) FIXME: Expected 0-1 arguments, but got 2 or more.
    super(message, ...args);
  }
}

async function createSplitClient({
  courseId,
  hasActiveSubscription,
  overrides,
  userId,
}: InitSplitArgs): Promise<void> {
  const isAnonymous = userId == null;
  if (isAnonymous) {
    return;
  }

  const { SplitFactory } = await import(
    /* webpackChunkName: "splitio" */ '@splitsoftware/splitio'
  );

  if (splitClient !== undefined) {
    splitClient.destroy();
    splitClient = undefined;
  }
  const factory = SplitFactory({
    core: {
      // @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
      authorizationKey: API_KEY,
      key: userId != null ? userId.toString() : 'anonymous',
    },
    features: DEFAULT_FEATURE_FLAGS,
  });
  splitClient = factory.client();
  featureOverrides = overrides;
  params = { hasActiveSubscription, courseId };
}

export async function initSplitClient(args: InitSplitArgs): Promise<void> {
  try {
    await createSplitClient(args);
  } catch (error) {
    splitClient = null;
    raven.captureException(error, {
      extra: { message: 'Failed to initialize Split client' },
    });
  }

  return new Promise((resolve, reject) => {
    const isAnonymousUser = args.userId == null;
    if (isAnonymousUser || splitClient == null) {
      resolve();
      return;
    }
    splitClient.on(splitClient.Event.SDK_READY, resolve);
    splitClient.on(splitClient.Event.SDK_READY_TIMED_OUT, () =>
      reject(new SplitTimeoutError()),
    );
  });
}

export function getTreatment(splitName: string): SplitTreatment {
  if (splitClient === undefined) {
    return 'control';
  }
  if (featureOverrides !== undefined && featureOverrides[splitName]) {
    return featureOverrides[splitName];
  }
  return splitClient.getTreatment(splitName, params);
}
