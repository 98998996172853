import * as split from './split';
import type { InitSplitArgs, SplitTreatment } from './types';

export function initSplitClient(args: InitSplitArgs): Promise<void> {
  return split.initSplitClient(args);
}

export function getTreatment(featureName: string): SplitTreatment {
  return split.getTreatment(featureName);
}

export function isFeatureEnabled(featureName: string): boolean {
  return getTreatment(featureName) === 'on';
}
